'use client'

import { useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'

import ErrorComponent from 'components/500'

const ErrorPage = ({ error }: { error: Error & { digest?: string } }) => {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return <ErrorComponent />
}

export default ErrorPage
